import React from "react";
import styles from "./Company.module.css";
import CarouselCompany from "../../components/CarouselCompany/CarouselCompany";

function Company() {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h1 id={styles.titleDesktop}>Veja quem confia na Ifé Assessoría </h1>
        <h1 id={styles.titleMobile}>Veja quem confia na Ifé</h1>{" "}
      </div>

      <div className={styles.carousel}>
        <CarouselCompany />
      </div>
    </div>
  );
}

export default Company;
