import React from "react";
import styles from "./Outdoor.module.css";
function Outdoor() {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <h1>ASSESSORIA EMPRESARIAL</h1>
        <h3>
          Escritório de contabilidade em São Paulo oferecendo a melhor solução.
        </h3>
      </div>
    </div>
  );
}

export default Outdoor;
