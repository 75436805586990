import React from "react";
import styles from "./SectorOffice.module.css";
function SectorOffice() {
  return (
    <div id={styles.firstSector}>
      <div id={styles.border}></div>
      <div id={styles.firstCard}>
        <h1>Soluções financeiras</h1>
      </div>
    </div>
  );
}

export default SectorOffice;
