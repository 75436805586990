import React from "react";
import styles from "./Form.module.css";
// import { useMail } from "../../hooks/useMail";
export default function Form() {
  const [data, setData] = React.useState({
    name: "",
    email: "",
    celPhone: "",
    message: "",
  });

  // const mail = useMail();

  function handleChange({ target }) {
    const { id, value } = target;
    setData({ ...data, [id]: value });
  }
  function handleSubmit(event) {
    console.log('test')
    event.preventDefault();
    // mail.send(data);
    // setData({ name: "", email: "", message: "" });
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.formRight}>
        <input
          required
          className={styles.input}
          value={data.name}
          type="text"
          id="name"
          placeholder="Nome"
          onChange={handleChange}
        />
        <input
          required
          className={styles.input}
          value={data.email}
          type="email"
          id="email"
          placeholder="Email"
          onChange={handleChange}
        />
        <input
          className={styles.input}
          value={data.celPhone}
          type="tel"
          id="celPhone"
          placeholder="WhatsApp"
          onChange={handleChange}
        />
      </div>
      <div className={styles.formLeft}>
        <textarea
          required
          rows="11"
          className={styles.input}
          value={data.message}
          type="text"
          id="message"
          placeholder="Digite sua mensagem aqui..."
          onChange={handleChange}
        />
        <div className={styles.buttonRow}>
          <button>Enviar</button>
        </div>
      </div>
    </form>
  );
}
