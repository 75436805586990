import React from "react";
import styles from "./Midias.module.css";
import { logos } from "../../constants/logosFooter";

export default function Midias() {
  return (
    <div className={styles.midias}>
      <h1> Nossas Redes</h1>
      <div>
        {logos.map((logo) => (
          <div key={logo.id + 1}>
            <a href={logo.link}>
              <img id={styles.logologoDesktop} src={logo.logo} alt="" />{" "}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
