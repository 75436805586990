import React from "react";
import GlobalStyles from "./GlobalStyles";
import Welcome from "./screen/Welcome/Welcome";
import Sectors from "./screen/Sectors/Sectors";
import About from "./screen/About/About";
import Transition from "./components/Transition/Transition";
import Office from "./screen/Office/Office";
import Tips from "./screen/Tips/Tips";
import Company from "./screen/Company/Company";
import Footer from "./components/Footer/Footer";
import Back from "./components/Back/Back";
function App() {
  return (
    <>
      <GlobalStyles />
      <Welcome />
      <Sectors />
      <Transition />

      <About />
      <Office />
      <Tips />
      <Company />
      <Footer />
      <Back />
    </>
  );
}

export default App;
