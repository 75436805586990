import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`

    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;

        color: black;
    }
    *, button, input{
        /* border: 0; */
        /* background: none; */
        font: 400 1.8rem/1 'Open Sans', sans-serif;
        outline:none;
        
    }
    html, body{
        background: white;
        width: 100%; 
        height: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    :root{
        font-size: 62.5%;
    }

`;
