import React from "react";
import Header from "../../components/Header/Header";
import Outdoor from "../../components/Outdoor/Outdoor";
import { Element } from "react-scroll";

export default function Welcome() {
  return (
    <>
      <Element name="top">
        <Header />
        <Outdoor />
      </Element>
    </>
  );
}
