import React from "react";
import styles from "./Tips.module.css";
import { tips } from "../../constants/tips";
import CardTips from "../../components/CardTips/CardTips";
import { Element } from "react-scroll";

function Tips() {
  return (
    <Element name="tips" className="element">
      <div className={styles.container}>
        <h1 className={styles.title}>
          NOSSAS DICAS <b className={styles.bold}>PARA VOCÊ</b>
        </h1>

        <div className={styles.carousel}>
          {tips.map((tip) => (
            <CardTips key={tip.id} tip={tip} />
          ))}
        </div>

        <div className={styles.cardsMobile}>
          {tips.map((tip) => (
            <CardTips key={tip.id} tip={tip} />
          ))}
        </div>
      </div>
    </Element>
  );
}

export default Tips;
