import React from 'react'
import styles from './CardTips.module.css'
function CardTips({tip}) {
    return (
        <div className={styles.container}>
            <h1>{tip.title}</h1>
            <p>{tip.description}</p>
        </div>
    )
}

export default CardTips
