import React from "react";
import styles from "./Footer.module.css";
import { contacts } from "../../constants/contacts";
import { Element } from "react-scroll";
import Midias from "../Midias/Midias";

import Form from "../Form/Form";
export default function Footer() {
  return (
    <Element name="footer" className="element">
      <footer className={styles.footer}>
        <div className={styles.title}>
          <div>
            <h1>Contato </h1>
            <h1>Ifé Assesoria</h1>
          </div>
          <div className={styles.data}>
            {contacts.map((contact) => (
              <div key={contact.id} className={styles.infodata}>
                <img src={contact.logo} alt="logo" />
                <h2>{contact.text}</h2>
              </div>
            ))}
          </div>
        </div>
        <Form />

        <Midias />
      </footer>
    </Element>
  );
}
