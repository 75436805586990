import React from "react";
import styles from "./ButtonSectors.module.css";

function ButtonSectors({ servico, setDados }) {
  const [active, setActive] = React.useState(false);
  function handleClick() {
    setDados(servico);
    setActive(!active);
  }

  return (
    <button onClick={handleClick} className={styles.button}>
      <div className={active ? styles.titleActive : styles.title}>
        <img id={styles.iconDesktop} src={servico.icon} alt="icon-ifé" />
        <img id={styles.iconMobile} src={servico.icon} alt="icon-ifé" />

        <h2>{servico.nome}</h2>
      </div>
      <div></div>
      {active && 
        <div className={styles.info}>
          <p className={styles.descricao} id={styles.text}>{servico.descricao}</p>
          <ul className={styles.lista} id={styles.text} >
            {servico.topicos.map((item) => (
              <li>{item}</li>
            ))}
          </ul>
          <h3 id={styles.text} className={styles.subtitulo}>{servico.subtitulo}</h3>
        </div>
      }
    </button>
  );
}

export default ButtonSectors;
