import React from "react";
import styles from "./Sectors.module.css";
import ButtonSectors from "../../components/ButtonSectors/ButtonSectors";
import CardSectors from "../../components/CardSectors/CardSectors";
import { sectors } from "../../constants/sectors";
import { Element } from "react-scroll";

function Sectors() {
  const [dados, setDados] = React.useState(sectors[0]);

  return (
    <Element name="sectors" className="element">
      <div className={styles.container}>
        <div className={styles.content}>
          <h1>Confira nossos Serviços</h1>

          <div className={styles.button}>
            {sectors.map((sector) => (
              <ButtonSectors
                key={sector.nome}
                servico={sector}
                setDados={setDados}
              />
            ))}
          </div>
        </div>

        <div className={styles.card}>
          <CardSectors dados={dados} />{" "}
        </div>
      </div>
    </Element>
  );
}

export default Sectors;
