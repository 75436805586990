
import iconFinanceiro from "../assets/Sectors/iconSector-dollar.svg";
import iconContabilidade from "../assets/Sectors/iconSector-stats.svg";
import iconContabilidadeFT from "../assets/Sectors/iconSector-bank.svg";

export const sectors = [
  {
    nome: "Financeiro",
    descricao:
      "Soluções financeiras personalizadas, para estruturar sua empresa e dar a melhor perspectiva para seu negócio.",
    topicos: [
      "Planejamento financeiro;",
      "Gestão de riscos;",
      "Análises de fluxos e mais.",
    ],
    subtitulo: "A melhor estrutura para sua tomada de decisão.",
    icon: iconFinanceiro
  },
  {
    nome: "Contabilidade",
    descricao:
      "Contabilidade que chega até sua empresa de forma simples e eficaz. Deixe a parte burocrática com a Ifé Assessoria, e veja sua empresa crescer substancialmente.",
    topicos: [
      "Escrituração contábil;",
      "Elaboração de relatórios contábeis;",
      "Declaração de Impostos de Renda;",
      "Contabilidade Societária (abertura, alteração e encerramento).",
    ],
    subtitulo: "",
    icon: iconContabilidade
  },
  {
    nome: "Contabilidade Fiscal e Tributária",
    descricao:
      "Tenha a legislação fiscal e tributária como aliada da sua empresa, diminua as despesas com tributos, dentro da Lei e de maneira eficaz.",
    topicos: [
      "Planejamento Tributário;",
      "Enquadramento Fiscal - Simples Nacional, Lucro Presumido e Lucro Real;",
      "Regularização;",
      "Análise Tributária e muito mais!",
    ],
    subtitulo: "",
    icon: iconContabilidadeFT
  }
];
