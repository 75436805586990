import React from 'react'
import styles from './Transition.module.css'
function Transition() {
    return (
        <div className={styles.container}>
            <h1>Solicite aqui uma de nossas especialidades </h1>
            <div className={styles.button}>
            <a  href = '/'>Fale conosco</a></div>
        </div>
    )
}

export default Transition
