import React from "react";
import logoDesktop from "../../assets/Welcome/logo-desktop.svg";
import logoMobile from "../../assets/Welcome/logo-mobile.svg";
import menu from "../../assets/Welcome/menu-lateral.svg";
import close from "../../assets/Welcome/close.svg";
import ContactDesktop from "../../assets/Welcome/iconWhatsapp-desktop.svg";
import ContactTablet from "../../assets/Welcome/iconWhatsapp-tablet.svg";
import styles from "./Header.module.css";
import { Link } from "react-scroll";
import { sidebarData } from "../../constants/sidebarData";
import Sidebar from "../../components/Sidebar/Sidebar";
export default function Header() {
  const [sidebar, setSidebar] = React.useState(false);

  function showSidebar() {
    setSidebar(!sidebar);
  }

  return (
    <div>
      <header className={styles.header}>
        <div className={styles.logo}>
          <a href="/">
            <img
              id={styles.logoDesktop}
              src={logoDesktop}
              alt="Logo Ifé-Assesoria"
            />
          </a>
          <a href="/">
            <img
              id={styles.logoMobile}
              src={logoMobile}
              alt="Logo Ifé-Assesoria"
            />
          </a>
        </div>

        <div className={styles.headerRight}>
          {sidebarData.map((data) => (
            <Link
              key={data.id}
              href="/"
              activeClass="active"
              to={data.path}
              spy={true}
              smooth={true}
              offset={data.offset}
              duration={500}
            >
              {data.text}
            </Link>
          ))}

          <Link
            className={styles.contact}
            href="/"
            activeClass="active"
            to="footer"
            spy={true}
            smooth={true}
            offset={45}
            duration={500}
          >
            <img
              id={styles.iconDesktop}
              src={ContactDesktop}
              alt="icon-whatsapp"
            />
            <img
              id={styles.iconTablet}
              src={ContactTablet}
              alt="icon-whatsapp"
            />

            <h1>Contate Ifé Assesoria</h1>
          </Link>
        </div>

        <nav className={styles.sidebar}>
          {sidebar ? (
            <div>
              <button onClick={showSidebar}>
                <img style={{ height: "8vw" }} alt="menu-ifé" src={close} />
              </button>
            </div>
          ) : (
            <button onClick={showSidebar}>
              <img alt="menu-ifé" src={menu} />
            </button>
          )}
        </nav>
      </header>

      {sidebar && (
        <div id={styles.sidebarMenu}>
          <Sidebar />
        </div>
      )}
    </div>
  );
}
