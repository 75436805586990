import LuImg from "./../assets/Company/Luci.jpg";
import EllaImg from "./../assets/Company/ella.png";
import ReImg from "./../assets/Company/renan.png";
import DanImg from "./../assets/Company/DanielCabrel.jpg";
import MakeImg from "./../assets/Company/makeda.jpeg";
import NebImg from "./../assets/Company/nebulosa.jpg";
export const companies = [
  {
    id: 0,
    imageMobile: LuImg,
    imageDesktop: LuImg,
    thumb: LuImg,
    name: "Luci Gonçalves",
    comment:
      "“Trabalhar com as meninas é tranquilidade na parte mais turbulento da nossa vida: financeiro. É compromisso que abraça nossos problemas do dia a dia, esquecimentos e falta de informação. Elas combatem tudo isso com muito carinho e atenção. Não troco por nada.”",
  },
  {
    id: 1,
    imageMobile: EllaImg,
    imageDesktop: EllaImg,
    thumb: EllaImg,
    name: "Instituto Ella Criações Educativas",
    comment:
      "“Há mais de um ano, vem prestando serviço de excelência e de compromisso para o Instituto Ella Criações Educativas. Formada por duas contadoras competentes e responsáveis, a Ifé Assessoria é uma empresa que articula conhecimento, clareza e comprometimento. A Ifé Assessoria é uma parceria do Instituto Ella, e desejamos vida longa a Ifé e a essa parceria.”",
  },
  {
    id: 2,
    imageMobile: MakeImg,
    imageDesktop: MakeImg,
    thumb: MakeImg,
    name: "Makeda Arquitetura",
    comment:
      "“A Makeda Arquitetura é grata por toda atenção e excelência no serviço prestado pela equipe da Ifé Assessoria. Atenção, prontidão e proatividade são algumas das muitas qualidades que a Makeda na experiência de trabalho com essa empresa incrível.”",
  },
  {
    id: 3,
    imageMobile: ReImg,
    imageDesktop: ReImg,
    thumb: ReImg,
    name: "Renan Kvacek, Cofundador SILVA Produtora",
    comment:
      "“A Ifé foi muito importante para estruturar e organizar a nossa gestão financeira e contábil. Com elas, descobrimos que tínhamos diversas questões para solucionar e aprendemos bastante sobre como ter um olhar mais assertivo para nossa empresa. Com o nosso crescimento, crescem as responsabilidades e temos a segurança de que estamos bem assessorados.”",
  },
  {
    id: 4,
    imageMobile: DanImg,
    imageDesktop: DanImg,
    thumb: DanImg,
    name: "Daniel Cabrel",
    comment:
      "“A Ifé é precisa, acerta objetivamente nas necessidades da empresa e pensa junto em como solucionar as questões importantes.”",
  },
  {
    id: 5,
    imageMobile: NebImg,
    imageDesktop: NebImg,
    thumb: NebImg,
    name: "Nebulosa Selo",
    comment:
      "“A Ifé cuida com muito respeito, atenção e carinho do nosso trabalho e somos muito gratos por isso. Todos nós da Nebulosa estamos seguros e felizes em contar com essa parceria para tomar as decisões mais corretas para nosso presente e futuro.”",
  },
];
