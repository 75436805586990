import React from "react";
import { CarouselProvider, Slider, Slide, Dot } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { companies } from "../../constants/companies";
import styles from "./CarouselCompany.module.css";

export default function CarouselCompany() {
  return (
    <CarouselProvider
      className={styles.carousel}
      naturalSlideWidth={100}
      isIntrinsicHeight={true}
      totalSlides={6}
      isPlaying={true}
      interval={3800}
    >
      <Slider className={styles.slider}>
        {companies.map((company) => (
          <Slide className={styles.slide} key={company.id} index={company.id}>
            <img
              id={styles.imageDesktop}
              src={company.imageDesktop}
              alt="Company"
            />
            <img
              id={styles.imageMobile}
              src={company.imageMobile}
              alt="Company"
            />

            <p>{company.comment}</p>
            <h1>{company.name}</h1>
          </Slide>
        ))}
      </Slider>
      <div className={styles.dotGroup}>
        {companies.map((company) => (
          <Dot key={company.id} slide={company.id}></Dot>
        ))}
      </div>
    </CarouselProvider>
  );
}
