import React from "react";
import styles from "./CardSectors.module.css";
function CardSectors({ dados }) {
  return (
    <div className={styles.card}>
      <h1>{dados.nome}</h1>
      <p>{dados.descricao}</p>
      <ul>
        {dados.topicos.map((item) => (
          <li>{item}</li>
        ))}
      </ul>
      <h2>{dados.subtitulo}</h2>
    </div>
  );
}

export default CardSectors;
