import React from "react";
import styles from "./About.module.css";
import { Element } from "react-scroll";

function About() {
  return (
    <Element name="about" className="element">
      <div className={styles.container}>
        <h1 className={styles.title}>SOBRE NÓS</h1>
        <p className={styles.content}>
        A Ifé Assessoria é a concretização de um sonho que vai na contramão do modelo tradicional de se fazer contabilidade, em um mercado onde pessoas negras são minorias.
        A proposta é tornar todos os processos burocráticos do meio contábil e financeiro mais acessíveis, e facilitar também o acesso às informações tributárias que são extremamente complexas no nosso País.
        A Ifé não é uma contabilidade online, prezamos a pessoalidade e proximidade com cada pessoa/empresa que nos procura, pois, independentemente do porte todos/todas e todes são importantes.
        </p>
      </div>
    </Element>
  );
}

export default About;
