import React from "react";
import { Link } from "react-scroll";
import styles from "./Back.module.css";
import Arrow from "../../assets/Footer/midias/Back/Back.svg";

export default function Back() {
  return (
    <div className={styles.back}>
      <Link
        activeClass="active"
        to="top"
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
      >
        <h1>Voltar ao topo</h1>
      </Link>

      <Link
        activeClass="active"
        to="top"
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
      >
        <div style={{ display: "grid", paddingLeft: "7%" }}>
          <img src={Arrow} alt="Arrow" />
          <img src={Arrow} alt="Arrow" />
        </div>
      </Link>
    </div>
  );
}
