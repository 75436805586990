import React from "react";
import CardOffice from "../../components/CardOffice/CardOffice";
import styles from "./Office.module.css";
function Office() {
  return (
    <div className={styles.container}>
      <CardOffice />
    </div>
  );
}

export default Office;
