export const tips = [
  {
    id: 0,
    title: "Organize suas finanças",
    description:
      "Controle cuidadosamente o fluxo de caixa e separe claramente os movimentos/bens pessoais dos da empresa.",
  },
  {
    id: 1,
    title: "Atue na formalidade",
    description:
      "Fugir dos impostos e da burocracia pode parecer vantajoso e menos complicado, mas várias portas se abrem com a regularização - além de passar uma imagem mais profissional e organizada.",
  },
  {
    id: 2,
    title: "Tenha noção dos custos",
    description:
      "Saiba de antemão todos os custos que devem ser incluídos nas projeções e evite surpresas desagradáveis.",
  },
];
