import React from "react";
import SectorOffice from "../SectorOffice/SectorOffice";
import styles from "./CardOffice.module.css";
export default function CardOffice() {
  return (
    <div className={styles.container}>
      <div className={styles.contentLeft}>
        <h1 className={styles.title}>O que oferecemos?</h1>
        <h3>
          Oferecemos soluções de contabilidade compatíveis com o seu ramo de
          atuação.
        </h3>
        <a id={styles.buttonDesktop} href="/">
          Vamos conversar
        </a>
      </div>

      <div className={styles.contentRight}>
        <SectorOffice />
        <div className={styles.sectors}>
          <div id={styles.border}></div>
          <div className={styles.sector}>
            <h1>Contabilidade</h1>
          </div>
        </div>
        <div className={styles.sectors}>
          <div id={styles.border}></div>
          <div className={styles.sector}>
            <h1>Contabilidade Fiscal e Tributária</h1>
          </div>
        </div>
      </div>
      <a id={styles.buttonMobile} href="/">
        Vamos conversar
      </a>
    </div>
  );
}
