import React from "react";
import { sidebarData } from "../../constants/sidebarData";
import { Link } from "react-scroll";
import Midias from "../../components/Midias/Midias";
import styles from "./Sidebar.module.css";
export default function Sidebar() {
  return (
    <div style={{ background: "#1E0F2A" }}>
      <div
        id={styles.sidebar}
        className="animate__animated animate__fadeInLeft"
      >
        {sidebarData.map((data) => (
          <Link
            className={styles.link}
            key={data.id}
            href="/"
            activeClass="active"
            to={data.path}
            spy={true}
            smooth={true}
            offset={data.offset}
            duration={500}
          >
            {data.text}
          </Link>
        ))}
        <Link
          className={styles.link}
          href="/"
          activeClass="active"
          to="footer"
          spy={true}
          smooth={true}
          offset={45}
          duration={500}
        >
          <button>CONTATO</button>
        </Link>

        <div className={styles.midia}>
          <Midias />
        </div>
      </div>
    </div>
  );
}
